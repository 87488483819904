const pass1b06AssayTissueTreeData = [
  [undefined, 'Rats: Endurance Training'],
  ['Rats: Endurance Training', 'Transcriptomics'],
  ['Rats: Endurance Training', 'Untargeted Proteomics'],
  ['Rats: Endurance Training', 'Untargeted Metabolomics'],
  ['Rats: Endurance Training', 'Targeted Metabolomics'],
  ['Rats: Endurance Training', 'Epigenomics'],
  ['Epigenomics', 'RRBS'],
  ['Epigenomics', 'ATAC-seq'],
  ['Targeted Metabolomics', 'Targeted Tricarboxylic Acid Cycle'],
  ['Targeted Metabolomics', 'Targeted Oxylipins'],
  ['Targeted Metabolomics', 'Targeted Nucleotides'],
  ['Targeted Metabolomics', 'Targeted Keto Acids'],
  ['Targeted Metabolomics', 'Targeted Ethanolamides'],
  ['Targeted Metabolomics', 'Targeted Amines'],
  ['Targeted Metabolomics', 'Targeted Acyl-CoA'],
  ['Untargeted Metabolomics', 'Untargeted Reversed-Phase Positive'],
  ['Untargeted Metabolomics', 'Untargeted Reversed-Phase Negative'],
  ['Untargeted Metabolomics', 'Untargeted Lipidomics Reversed-Phase Positive'],
  ['Untargeted Metabolomics', 'Untargeted Lipidomics Reversed-Phase Negative'],
  ['Untargeted Metabolomics', 'Untargeted Ion-Pair Negative'],
  ['Untargeted Metabolomics', 'Untargeted HILIC-Positive'],
  ['Untargeted Proteomics', 'Protein Ubiquitination'],
  ['Untargeted Proteomics', 'Global Proteomics'],
  ['Untargeted Proteomics', 'Phosphoproteomics'],
  ['Untargeted Proteomics', 'Acetyl Proteomics'],
  ['Transcriptomics', 'RNA-seq'],
  ['ATAC-seq', 'White Adipose'],
  ['ATAC-seq', 'Brown Adipose'],
  ['ATAC-seq', 'Liver'],
  ['ATAC-seq', 'Lung'],
  ['ATAC-seq', 'Kidney'],
  ['ATAC-seq', 'Heart'],
  ['ATAC-seq', 'Gastrocnemius'],
  ['ATAC-seq', 'Hippocampus'],
  ['RRBS', 'White Adipose'],
  ['RRBS', 'Brown Adipose'],
  ['RRBS', 'Liver'],
  ['RRBS', 'Lung'],
  ['RRBS', 'Kidney'],
  ['RRBS', 'Heart'],
  ['RRBS', 'Gastrocnemius'],
  ['RRBS', 'Hippocampus'],
  ['Targeted Tricarboxylic Acid Cycle', 'White Adipose'],
  ['Targeted Tricarboxylic Acid Cycle', 'Brown Adipose'],
  ['Targeted Tricarboxylic Acid Cycle', 'Liver'],
  ['Targeted Tricarboxylic Acid Cycle', 'Lung'],
  ['Targeted Tricarboxylic Acid Cycle', 'Kidney'],
  ['Targeted Tricarboxylic Acid Cycle', 'Heart'],
  ['Targeted Tricarboxylic Acid Cycle', 'Gastrocnemius'],
  ['Targeted Tricarboxylic Acid Cycle', 'Hippocampus'],
  ['Targeted Tricarboxylic Acid Cycle', 'Plasma'],
  ['Targeted Oxylipins', 'White Adipose'],
  ['Targeted Oxylipins', 'Brown Adipose'],
  ['Targeted Oxylipins', 'Liver'],
  ['Targeted Oxylipins', 'Lung'],
  ['Targeted Oxylipins', 'Kidney'],
  ['Targeted Oxylipins', 'Heart'],
  ['Targeted Oxylipins', 'Gastrocnemius'],
  ['Targeted Oxylipins', 'Hippocampus'],
  ['Targeted Oxylipins', 'Plasma'],
  ['Targeted Nucleotides', 'White Adipose'],
  ['Targeted Nucleotides', 'Liver'],
  ['Targeted Nucleotides', 'Heart'],
  ['Targeted Nucleotides', 'Gastrocnemius'],
  ['Targeted Keto Acids', 'White Adipose'],
  ['Targeted Keto Acids', 'Liver'],
  ['Targeted Keto Acids', 'Heart'],
  ['Targeted Keto Acids', 'Gastrocnemius'],
  ['Targeted Keto Acids', 'Plasma'],
  ['Targeted Ethanolamides', 'White Adipose'],
  ['Targeted Ethanolamides', 'Brown Adipose'],
  ['Targeted Ethanolamides', 'Liver'],
  ['Targeted Ethanolamides', 'Lung'],
  ['Targeted Ethanolamides', 'Kidney'],
  ['Targeted Ethanolamides', 'Heart'],
  ['Targeted Ethanolamides', 'Gastrocnemius'],
  ['Targeted Ethanolamides', 'Hippocampus'],
  ['Targeted Ethanolamides', 'Plasma'],
  ['Targeted Amines', 'White Adipose'],
  ['Targeted Amines', 'Brown Adipose'],
  ['Targeted Amines', 'Liver'],
  ['Targeted Amines', 'Lung'],
  ['Targeted Amines', 'Kidney'],
  ['Targeted Amines', 'Heart'],
  ['Targeted Amines', 'Gastrocnemius'],
  ['Targeted Amines', 'Hippocampus'],
  ['Targeted Amines', 'Plasma'],
  ['Targeted Acyl-CoA', 'White Adipose'],
  ['Targeted Acyl-CoA', 'Liver'],
  ['Targeted Acyl-CoA', 'Heart'],
  ['Targeted Acyl-CoA', 'Gastrocnemius'],
  ['Untargeted Reversed-Phase Positive', 'White Adipose'],
  ['Untargeted Reversed-Phase Positive', 'Brown Adipose'],
  ['Untargeted Reversed-Phase Positive', 'Liver'],
  ['Untargeted Reversed-Phase Positive', 'Lung'],
  ['Untargeted Reversed-Phase Positive', 'Kidney'],
  ['Untargeted Reversed-Phase Positive', 'Heart'],
  ['Untargeted Reversed-Phase Positive', 'Gastrocnemius'],
  ['Untargeted Reversed-Phase Positive', 'Hippocampus'],
  ['Untargeted Reversed-Phase Positive', 'Plasma'],
  ['Untargeted Reversed-Phase Negative', 'White Adipose'],
  ['Untargeted Reversed-Phase Negative', 'Brown Adipose'],
  ['Untargeted Reversed-Phase Negative', 'Liver'],
  ['Untargeted Reversed-Phase Negative', 'Lung'],
  ['Untargeted Reversed-Phase Negative', 'Kidney'],
  ['Untargeted Reversed-Phase Negative', 'Heart'],
  ['Untargeted Reversed-Phase Negative', 'Gastrocnemius'],
  ['Untargeted Reversed-Phase Negative', 'Hippocampus'],
  ['Untargeted Reversed-Phase Negative', 'Plasma'],
  ['Untargeted Lipidomics Reversed-Phase Positive', 'White Adipose'],
  ['Untargeted Lipidomics Reversed-Phase Positive', 'Brown Adipose'],
  ['Untargeted Lipidomics Reversed-Phase Positive', 'Liver'],
  ['Untargeted Lipidomics Reversed-Phase Positive', 'Lung'],
  ['Untargeted Lipidomics Reversed-Phase Positive', 'Kidney'],
  ['Untargeted Lipidomics Reversed-Phase Positive', 'Heart'],
  ['Untargeted Lipidomics Reversed-Phase Positive', 'Gastrocnemius'],
  ['Untargeted Lipidomics Reversed-Phase Positive', 'Hippocampus'],
  ['Untargeted Lipidomics Reversed-Phase Positive', 'Plasma'],
  ['Untargeted Lipidomics Reversed-Phase Negative', 'White Adipose'],
  ['Untargeted Lipidomics Reversed-Phase Negative', 'Brown Adipose'],
  ['Untargeted Lipidomics Reversed-Phase Negative', 'Liver'],
  ['Untargeted Lipidomics Reversed-Phase Negative', 'Lung'],
  ['Untargeted Lipidomics Reversed-Phase Negative', 'Kidney'],
  ['Untargeted Lipidomics Reversed-Phase Negative', 'Heart'],
  ['Untargeted Lipidomics Reversed-Phase Negative', 'Gastrocnemius'],
  ['Untargeted Lipidomics Reversed-Phase Negative', 'Hippocampus'],
  ['Untargeted Lipidomics Reversed-Phase Negative', 'Plasma'],
  ['Untargeted Ion-Pair Negative', 'White Adipose'],
  ['Untargeted Ion-Pair Negative', 'Brown Adipose'],
  ['Untargeted Ion-Pair Negative', 'Liver'],
  ['Untargeted Ion-Pair Negative', 'Lung'],
  ['Untargeted Ion-Pair Negative', 'Kidney'],
  ['Untargeted Ion-Pair Negative', 'Heart'],
  ['Untargeted Ion-Pair Negative', 'Gastrocnemius'],
  ['Untargeted Ion-Pair Negative', 'Hippocampus'],
  ['Untargeted Ion-Pair Negative', 'Plasma'],
  ['Untargeted HILIC-Positive', 'Vena Cava'],
  ['Untargeted HILIC-Positive', 'White Adipose'],
  ['Untargeted HILIC-Positive', 'Brown Adipose'],
  ['Untargeted HILIC-Positive', 'Liver'],
  ['Untargeted HILIC-Positive', 'Small Intestine'],
  ['Untargeted HILIC-Positive', 'Lung'],
  ['Untargeted HILIC-Positive', 'Ovaries'],
  ['Untargeted HILIC-Positive', 'Testes'],
  ['Untargeted HILIC-Positive', 'Spleen'],
  ['Untargeted HILIC-Positive', 'Colon'],
  ['Untargeted HILIC-Positive', 'Adrenal'],
  ['Untargeted HILIC-Positive', 'Kidney'],
  ['Untargeted HILIC-Positive', 'Heart'],
  ['Untargeted HILIC-Positive', 'Vastus Lateralis'],
  ['Untargeted HILIC-Positive', 'Gastrocnemius'],
  ['Untargeted HILIC-Positive', 'Hypothalamus'],
  ['Untargeted HILIC-Positive', 'Cortex'],
  ['Untargeted HILIC-Positive', 'Hippocampus'],
  ['Untargeted HILIC-Positive', 'Plasma'],
  ['Protein Ubiquitination', 'Liver'],
  ['Protein Ubiquitination', 'Heart'],
  ['Global Proteomics', 'White Adipose'],
  ['Global Proteomics', 'Liver'],
  ['Global Proteomics', 'Lung'],
  ['Global Proteomics', 'Kidney'],
  ['Global Proteomics', 'Heart'],
  ['Global Proteomics', 'Gastrocnemius'],
  ['Global Proteomics', 'Cortex'],
  ['Phosphoproteomics', 'White Adipose'],
  ['Phosphoproteomics', 'Liver'],
  ['Phosphoproteomics', 'Lung'],
  ['Phosphoproteomics', 'Kidney'],
  ['Phosphoproteomics', 'Heart'],
  ['Phosphoproteomics', 'Gastrocnemius'],
  ['Phosphoproteomics', 'Cortex'],
  ['Acetyl Proteomics', 'Liver'],
  ['Acetyl Proteomics', 'Heart'],
  ['RNA-seq', 'Vena Cava'],
  ['RNA-seq', 'White Adipose'],
  ['RNA-seq', 'Brown Adipose'],
  ['RNA-seq', 'Liver'],
  ['RNA-seq', 'Small Intestine'],
  ['RNA-seq', 'Lung'],
  ['RNA-seq', 'Ovaries'],
  ['RNA-seq', 'Testes'],
  ['RNA-seq', 'Spleen'],
  ['RNA-seq', 'Colon'],
  ['RNA-seq', 'Adrenal'],
  ['RNA-seq', 'Kidney'],
  ['RNA-seq', 'Heart'],
  ['RNA-seq', 'Vastus Lateralis'],
  ['RNA-seq', 'Gastrocnemius'],
  ['RNA-seq', 'Hypothalamus'],
  ['RNA-seq', 'Cortex'],
  ['RNA-seq', 'Hippocampus'],
  ['RNA-seq', 'Blood Rna'],
];

export default pass1b06AssayTissueTreeData;
