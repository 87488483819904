const exerciseBenefitsReferenceData = [
  {
    citationNo: 1,
    title: 'An Overview of the Beneficial Effects of Exercise on Health and Performance',
    author: 'Kramer A',
    journal: 'Advances in Experimental Medicine and Biology',
    publicationIssue: '2020;1228:3-22',
    doi: '10.1007/978-981-15-1792-1_1',
  },
  {
    citationNo: 2,
    title: 'American College of Sports Medicine Position Stand. Quantity and Quality of Exercise for Developing and Maintaining Cardiorespiratory, Musculoskeletal, and Neuromotor Fitness in Apparently Healthy Adults: Guidance for Prescribing Exercise',
    author: 'Garber CE, Blissmer B, Deschenes MR, et al',
    journal: 'Medicine and Science in Sports and Exercise',
    publicationIssue: '2011;43(7):1334-59',
    doi: '10.1249/MSS.0b013e318213fefb',
  },
  {
    citationNo: 3,
    title: 'Exercise and Metabolic Health: Beyond Skeletal Muscle',
    author: 'Thyfault JP, Bergouignan A',
    journal: 'Diabetologia',
    publicationIssue: '2020;63(8):1464-1474',
    doi: '10.1007/s00125-020-05177-6',
  },
  {
    citationNo: 4,
    title: 'The Physical Activity Guidelines for Americans',
    author: 'Piercy KL, Troiano RP, Ballard RM, et al',
    journal: 'Jama',
    publicationIssue: '2018;320(19):2020-2028',
    doi: '10.1001/jama.2018.14854',
  },
  {
    citationNo: 5,
    title: 'Statement on Exercise: Benefits and Recommendations for Physical Activity Programs for All Americans. A Statement for Health Professionals by the Committee on Exercise and Cardiac Rehabilitation of the Council on Clinical Cardiology, American Heart Association',
    author: 'Fletcher GF, Balady G, Blair SN, et al',
    journal: 'Circulation',
    publicationIssue: '1996;94(4):857-62',
    doi: '10.1161/01.cir.94.4.857',
  },
  {
    citationNo: 6,
    title: 'Exercise Mitigates Age-Related Metabolic Diseases by Improving Mitochondrial Dysfunction',
    author: 'Jia D, Tian Z, Wang R',
    journal: 'Ageing Research Reviews',
    publicationIssue: '2023;91:102087',
    doi: '10.1016/j.arr.2023.102087',
  },
  {
    citationNo: 7,
    title: 'Exercise as medicine - evidence for prescribing exercise as therapy in 26 different chronic diseases',
    author: 'Pedersen BK, Saltin B',
    journal: 'Scand J Med Sci Sports',
    publicationIssue: '2015 Dec:25 Suppl 3:1-72',
    doi: '10.1111/sms.12581',
  },
];

export default exerciseBenefitsReferenceData;
